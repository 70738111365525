import React from "react";
import styled from "styled-components";
import useStore from "../utils/useStore";
import { ReactComponent as IncomeIcon } from "../assets/income.svg";
import { ReactComponent as Expense } from "../assets/expense.svg";
import { ReactComponent as Report } from "../assets/summarize.svg";

const Wrapper = styled.div`
  height: 70px;
  background: #d9d9d9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  color: #432baf;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
`;

const Footer = () => {
  const activeRoute = useStore((state: any) => state.activeRoute);
  const setRoute = useStore((state: any) => state.setRoute);

  return (
    <Wrapper>
      <Expense width="50" height="50" fill={activeRoute=== 'expense'? "#432baf": "#635d7d"} onClick={()=>setRoute('expense')}/>
      <IncomeIcon width="50" height="50" fill={activeRoute=== 'income'? "#432baf": "#635d7d"} onClick={()=>setRoute('income')}/>
      <Report width="50" height="50" fill={activeRoute=== 'report'? "#432baf": "#635d7d"} onClick={()=>setRoute('report')}/>
    </Wrapper>
  );
};

export default Footer;
