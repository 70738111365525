import React from "react";
import styled from "styled-components";

const LargeButton = styled.button`
  padding: 10px 30px;
  font-size: 16px;
  background-color: #65558f;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    background-color: #8d7eb2; // Darken background color on hover
  }

  &:disabled {
    background-color: #cccccc; // Set background color for disabled state
    color: #666666; // Set text color for disabled state
    cursor: not-allowed; // Change cursor to not-allowed
    opacity: 0.6; // Reduce opacity for disabled state
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = ({ disabled, text, onClick }: any) => {
  return (
    <Wrapper>
      <LargeButton disabled={!!disabled} onClick={onClick}>{text}</LargeButton>
    </Wrapper>
  );
};

export default Button;
