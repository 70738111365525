import React, { useState } from "react";
import styled from "styled-components";
import Button from './action-button';
import ChipsComponent from "./chips";
import useStore from '../utils/useStore';

const StyledInput = styled.input`
    height: 30px;
    margin: 20px;
    width: 80%;
`;

const ChipsWrapper = styled.div`
    margin: 20px;
`;

const Income = () => {
    const [categoryName, setCategoryName] = useState('');
    const setExpenseSourceExpense = useStore((state: any) => state.setExpenseSourceExpense);
    const onAddClick = ()=>{
        setExpenseSourceExpense(categoryName)
        setCategoryName('');
    }
  return (
    <div>
      <div>Here you can add your expense category, other features are coming soon!</div>

      <StyledInput type="text" value={categoryName} onChange={(e)=> setCategoryName(e.target.value)}></StyledInput>
      <Button text="Add Category" onClick={onAddClick} />
     <ChipsWrapper><ChipsComponent onSelect={()=> {}} value="" showDelete/></ChipsWrapper> 
    </div>
  );
};

export default Income;
