import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 70px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #432baf;
`;

const Header = () => {
  return <Wrapper>Lekkad Book</Wrapper>;
};

export default Header;
