import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import useStore from "../utils/useStore";



// const chips = [{ label: "Food" }, { label: "Fuel" }, { label: "Other" }, { label: "Other grocerries" }, { label: "unknown" },{ label: "Hello world" }];

// const selectedChipIndex = 1;

interface ChipsComponentProps {
  showDelete?: boolean;
  onSelect: (reason: string) => void;
  value: string;
}
/**
 * Defines a functional component that renders a list of chips.
 * This component uses styled-components for styling.
 * @returns {JSX.Element} component
 */
const ChipsComponent: React.FC<ChipsComponentProps> = ({
  showDelete,
  onSelect,
  value,
}) => {
  const expenseSources = useStore((state: any) => state.expenseSources);
  const deleteExpenseSourceExpense = useStore(
    (state: any) => state.deleteExpenseSourceExpense
  );
  const [selectedChipIndex, setSelectedChipIndex] = useState<number>(
    expenseSources.findIndex((e: any) => e.label === value)
  );

  useEffect(() => {
    setSelectedChipIndex(
      expenseSources.findIndex((e: any) => e.label === value)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSelect = (index: number) => {
    setSelectedChipIndex(index);
  };

  return (
    <ChipsContainer>
      {expenseSources.map((chip: any, index: number) => (
        <Chip
          key={index}
          selected={index === selectedChipIndex}
          onClick={() => {
            handleSelect(index);
            onSelect(chip.label);
          }}
        >
          {chip.label}
          {showDelete && (
            <DeleteIcon
              onClick={(e) => {
                debugger;
                deleteExpenseSourceExpense(chip.label);
                e.stopPropagation();
              }}
            >
              x
            </DeleteIcon>
          )}
        </Chip>
      ))}
    </ChipsContainer>
  );
};

export default ChipsComponent;
// / Styled-components for styling the chips
const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

interface ChipProps {
  selected: boolean;
}

const Chip = styled.div<ChipProps>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #1d192b;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #e8def8;
      border: 1px solid #fff;
    `}
`;

const DeleteIcon = styled.span`
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  color: #1d192b;
`;
