import React, { useState } from "react";
import useStore from "../utils/useStore";
import styled from "styled-components";
import ChipsComponent from "./chips";
import Button from "./action-button";

const Expense: React.FC = () => {
  const [amount, setAmount] = useState<any>();
  const [reason, setReason] = useState("");
  const addExpense = useStore((state: any) => state.addExpense);

  const add = () => {
    addExpense(amount, reason);
    setAmount(0);
    setReason("");
  };

  return (
    <div>
      <InputContainer>
        <InputLabel htmlFor="input-box">Add Expense</InputLabel>
        <InputField
          type="number"
          id="input-box"
          value={amount}
          placeholder="Enter Amount Here"
          onChange={(e) => setAmount(e.target.value)}
        />
        <HelperText>(This will save in your browser)</HelperText>
        <ChipsWrapper>
          <ChipsComponent onSelect={setReason} value={reason} />
        </ChipsWrapper>
        <Button text="Add Expense" onClick={add} />
      </InputContainer>
    </div>
  );
};

export default Expense;

const ChipsWrapper = styled.div`
  margin: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const InputLabel = styled.label`
  font-weight: bold;
  margin: 8px 20px;
  color: #333;
`;

const InputField = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 20px;
`;

const HelperText = styled.small`
  margin-top: 4px;
  font-size: 12px;
  color: #666;
  margin: 4px 20px;
`;
