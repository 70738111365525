import React from "react";
import useStore from "../utils/useStore";

const Report = () => {
    const expense = useStore((state: any) => state.expense);

    return(<div><div>Expense (grouping featurey will come soon)</div>
    <br/>
    {expense.map((e: any) => <div>{e.amount} at - for {e.reason}</div>)}
    </div>)

}

export default Report;