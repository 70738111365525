import { create } from 'zustand';
import { persist } from 'zustand/middleware'

interface StoreState {
    expense: any[];
    addExpense: (amount: number, reason: string) => void;
    activeRoute: string;
    expenseSources: { label: string }[]
}

const useStore = create(
    persist(
        (set, get) => ({
            expense: [],
            activeRoute: 'expense',
            expenseSources: [{ label: "Food" }, { label: "Fuel" }, { label: "Other" }],
            setExpenseSourceExpense: (expSrc: string) => set((state: StoreState) => {
                return ({ expenseSources: [...state.expenseSources, { label: expSrc }] })
            }),
            deleteExpenseSourceExpense: (expSrc: string) => set((state: StoreState) => {
                return ({ expenseSources: state.expenseSources.filter(e => e.label !== expSrc) })
            }),
            setRoute: (route: string) => set({ activeRoute: route }),
            addExpense: (amount: number, reason: string) => set((state: StoreState) => {
                return ({ expense: [...state.expense, { amount, reason, timeStamp: new Date() }] })
            }),
        }),
        {
            name: 'lekkad-book',
        },
    ),
)

export default useStore;