import React from "react";
import Header from "./components/header";
import Footer from './components/footer';
import Expense from './components/expense';
import useStore from "./utils/useStore";
import Report from "./components/report";
import Income from "./components/income";

import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;
    font-family: "M PLUS Code Latin", monospace;
    font-optical-sizing: auto;
    background: #fff;
}
`;

const Wrapper = styled.div`
  margin: 0px;
`;

function App() {
  const activeRoute = useStore((state: any) => state.activeRoute);

  return (
    <>
      <GlobalStyles />
      <Wrapper>
        <Header />
        {activeRoute === 'expense' && <Expense />}
        {activeRoute === 'income' && <Income />}
        {activeRoute === 'report' && <Report />}

        <Footer />
      </Wrapper>
    </>
  );
}

export default App;
